import { HttpAuth } from "../../config/Http";
import { changeNotify } from "../../store/actions/notify.action";
import { changeModal } from '../../store/actions/modal.action';
import moment from 'moment/moment.js';

export const actionTypes = {
  CHANGE_CALENDARIO: "CHANGE_CALENDARIO",
  CHANGE_QUERY_SELECTED: "CHANGE_QUERY_SELECTED",
  CHANGE_MEDICO: "CHANGE_MEDICO",
  CHANGE_DAYSAVAILABLE: "CHANGE_DAYSAVAILABLE",
  CHANGE_AGENDA: "CHANGE_AGENDA"
};


export const changeCalendario = (payload) => ({
  type: actionTypes.CHANGE_CALENDARIO,
  payload,
});

export const change_days_available = (payload) => ({
  type: actionTypes.CHANGE_DAYSAVAILABLE,
  payload,
});

export const change_query_selected = (payload) => ({
  type: actionTypes.CHANGE_QUERY_SELECTED,
  payload,
});

export const change_medico_all = (payload) => ({
  type: actionTypes.CHANGE_MEDICO,
  payload,
});

export const query_call_agenda = (payload) => ({
  type: actionTypes.CHANGE_AGENDA,
  payload
})

export const query_call_medico = (medico_id, dt) => (dispatch) => {
    return HttpAuth.get(`/busca_calendario/${medico_id}/${dt}`).then((res) => { 
      dispatch(changeCalendario(res.data.days))
      dispatch(change_days_available(res.data.days_available))
    }
  );
};

export const query_call_medico_all = () => (dispatch) => {
  return HttpAuth.get(`/medicos/all`).then((res) => dispatch(change_medico_all(res.data))
  );
};


export const query_call_agenda_limpar = (agenda_id, medico_id, date_calendar) => (dispatch) => {
  return HttpAuth.get(`/limpar/${agenda_id}`).then((res) => {
    if (typeof res !== "undefined") {
        dispatch(query_call_medico(medico_id, moment(date_calendar).format('YYYY-MM-DD')));
        dispatch(
          changeNotify({  
            open: true,
            msg: "Horário liberado!",
            class: "success",
          })
        );
    } else {
      dispatch(changeModal({open: false}))
      dispatch(
        changeNotify({
          open: true,
          msg: "Falha na requisição!",
          class: "error",
        })
      );
    }
   }
  );
};

export const query_call_agenda_remover_fila = (agenda_id, medico_id, date_calendar) => (dispatch) => {
  return HttpAuth.get(`/removerfila/${agenda_id}`).then((res) => {
    if (typeof res !== "undefined") {
        dispatch(query_call_medico(medico_id, moment(date_calendar).format('YYYY-MM-DD')));
        dispatch(
          changeNotify({
            open: true,
            msg: "Paciente removido da fila de espera!",
            class: "success",
          })
        );
    } else {
      dispatch(changeModal({open: false}))
      dispatch(
        changeNotify({
          open: true,
          msg: "Falha na requisição!",
          class: "error",
        })
      );
    }
   }
  );
};

export const query_call_agenda_ausentar = (agenda_id, medico_id, date_calendar) => (dispatch) => {
  return HttpAuth.get(`/ausentar/${agenda_id}`).then((res) => {
    if (typeof res !== "undefined") {
        dispatch(query_call_medico(medico_id, moment(date_calendar).format('YYYY-MM-DD')));
        dispatch(
          changeNotify({
            open: true,
            msg: "Paciente definido como ausente!",
            class: "success",
          })
        );
    } else {
      dispatch(changeModal({open: false}))
      dispatch(
        changeNotify({
          open: true,
          msg: "Falha na requisição!",
          class: "error",
        })
      );
    }
   }
  );
};

export const query_call_agenda_bloquear = (agenda_id, medico_id, date_calendar) => (dispatch) => {
  return HttpAuth.get(`/bloquear/${agenda_id}`).then((res) => {
    if (typeof res !== "undefined") {
        dispatch(query_call_medico(medico_id, moment(date_calendar).format('YYYY-MM-DD')));
        dispatch(
          changeNotify({
            open: true,
            msg: "Bloqueio realizado com sucesso!",
            class: "success",
          })
        );
    } else {
      dispatch(changeModal({open: false}))
      dispatch(
        changeNotify({
          open: true,
          msg: "Falha na requisição!",
          class: "error",
        })
      );
    }
   }
  );
};


export const query_call_agendar_paciente = (agenda_id, paciente_id, medico_id, date_calendar) => (dispatch) => {
  return HttpAuth.get(`/agendar/${paciente_id}/${agenda_id}`).then((res) => {
    if (typeof res !== "undefined") {
        dispatch(query_call_medico(medico_id, moment(date_calendar).format('YYYY-MM-DD')));
        dispatch(changeModal({open: false}))
        dispatch(
          changeNotify({
            open: true,
            msg: "Paciente agendado com sucesso!",
            class: "success",
          })
        );
    } else {
      dispatch(changeModal({open: false}))
      dispatch(
        changeNotify({
          open: true,
          msg: "Falha na requisição!",
          class: "error",
        })
      );
    }
   }
  );
};