import { actionTypes } from '../actions/settings.action';

const initialState = {
    setting: {
        usuario: {},
        endereco: {}
    },
    setting_edit: {}  

}
 //eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {

    case actionTypes.INDEX:
        return { 
          ...state,
          setting: {
            ...state.setting,
            ...payload
          }
      }

      case actionTypes.CHANGE:
        return { 
          ...state,
          setting_edit: {
            ...state.setting_edit,
            ...payload
          }
      }
      
      case actionTypes.SUCCESS:
        return {
            ...state, 
            success: payload
        }

    case actionTypes.ERROR:
        return {
            ...state,
            error: payload
        }

    default:
      return state
    }
};
