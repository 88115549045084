import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Routes from "./routes";
import "./App.css";
import { Loading, Notify, Alert } from "./components/components_redux";

import firebase from "firebase/app";
import firebaseConfig from "./services/firebase/firebase.config";
import { MemedProvider } from "./hooks/useMemed";

const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

const App = () => {
  return (
    <Provider store={store}>
      <MemedProvider>
        <Fragment>
          <Alert />
          <Notify />
          <Loading />
          <Routes />
        </Fragment>
      </MemedProvider>
    </Provider>
  );
};

export default App;
