import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const Call = lazy(() => import("../pages/Call"));
const Login = lazy(() => import("../pages/Login"));
const Prontuario = lazy(() => import("../pages/Prontuario"));
const WaitingList = lazy(() => import("../pages/WaitingList"));
const Settings = lazy(() => import("../pages/Settings"));
const PdfView = lazy(() => import("../pages/PdfView"));
const CadPacientes = lazy(() => import("../pages/Pacientes/Cadastro"));
const ListPacientes = lazy(() => import("../pages/Pacientes/Lista"));
const HistPacientes = lazy(() => import("../pages/Pacientes/Historico"));
const Agenda = lazy(() => import("../pages/Agenda"));
const Expediente = lazy(() => import("../pages/Expediente"));

const Routes = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            Carregando...
          </div>
        }
      >
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={Login} />
          <Route
            exact
            path="/call/:doctorId/:patientId/:callId"
            component={Call}
          />
          <Route
            exact
            path="/prontuario/:id/:idAgenda"
            component={Prontuario}
          />
          <Route exact path="/atendimentos" component={WaitingList} />
          <Route exact path="/perfil" component={Settings} />
          <Route exact path="/visualizar/:type" component={PdfView} />

          {/*  PACIENTES */}
          <Route exact path="/pacientes" component={ListPacientes} />
          <Route exact path="/pacientes/novo" component={CadPacientes} />
          <Route exact path="/pacientes/historico/:id" component={HistPacientes} />

          {/*  AGENDA */} 
          <Route exact path="/agenda" component={Agenda} /> 
          <Route exact path="/expediente" component={Expediente} /> 
          

        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
