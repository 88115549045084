import { actionTypes } from '../actions/calendario.action';
const initialState = {
    days: [],
    days_available: [],
    medicos: [],
    agenda_id: null,
    medico_id: null,
    date_calendar: null
}

 //eslint-disable-next-line
 export default (state = initialState, { type, payload }) => {
    switch (type) {
  
      case actionTypes.CHANGE_MEDICO:
        return { 
          ...state,
          medicos: [
            ...payload
           ]
      }

      case actionTypes.CHANGE_CALENDARIO:
          return { 
            ...state,
            days: [
              ...payload
             ]
        }
  
      case actionTypes.CHANGE_DAYSAVAILABLE:
          return { 
            ...state,
            days_available: [
              ...payload
             ],
      }

      case actionTypes.CHANGE_AGENDA:
        return { ...state, ...payload };
     
      default:
        return state
      }
  };

