import { HttpAuth } from "../../config/Http";
import { changeNotify } from "./notify.action";

export const actionTypes = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};


export const success = (payload) => ({
  type: actionTypes.SUCCESS,
  payload,
});

export const error = (payload) => ({
  type: actionTypes.ERROR,
  payload,
});



// STORE EXPEDIENTE
export const store = (data) => (dispatch) => {
  return HttpAuth.post("/expedientes/store", data).then((res) => {
    if (typeof res !== "undefined") {
          if(res.data.ok) {
            dispatch(
              changeNotify({
                open: true,
                msg: res.data.ok,
                class: "success",
              })
            );
        }
          if(res.data.errors) {
              dispatch(
                changeNotify({
                  open: true,
                  msg: res.data.errors,
                  class: "error",
                })
              );
          }
        if(res.data.dtfinal) {
            dispatch(
              changeNotify({
                open: true,
                msg: res.data.dtfinal,
                class: "error",
              })
            );
        }
        
        if(res.data.dtinicio) {
          dispatch(
            changeNotify({
              open: true,
              msg: res.data.dtinicio,
              class: "error",
            })
          );
      }
    }
  });
};


// DEL EXPEDIENTE
export const del = (id) => (dispatch) => {
  return HttpAuth.get("/expedientes/del/" + id).then((res) => {
    if (typeof res !== "undefined") {
          if(res.data.ok) {
            dispatch(
              changeNotify({
                open: true,
                msg: res.data.ok,
                class: "success",
              })
            );
        }
          if(res.data.warning) {
              dispatch(
                changeNotify({
                  open: true,
                  msg: res.data.warning,
                  class: "error",
                })
              );
          }
    }
  });
};
