import { actionTypes } from '../actions/pacientes.action';

const initialState = {
    pacientes: {},
    paciente: {
     /*  convenios: [
        {
          id: 2,
          nome: 'Unimed',
          check: false
        },
        {
          id: 3,
          nome: 'Bradesco',
          check: false
        },
        {
          id: 4,
          nome: 'Unimed Volta Redonda',
          check: true
        }
      ] */
    },
    paciente_historico: {},
    error: {},
    success: false,
}
 //eslint-disable-next-line
export default (state = initialState, { type, payload, isLoadMore }) => {
  switch (type) {
    case actionTypes.INDEX:
        if(isLoadMore) {
            payload.pacientes.data = state.pacientes.data.concat(payload.pacientes.data);
        }
        return { ...state, ...payload }


    case actionTypes.HISTORICO:
      return {
        ...state,
        paciente_historico: {
          ...state.paciente_historico,
          ...payload
        }
      }
    case actionTypes.INDEXPACIENTE:
          return { 
            ...state,
            paciente: {
              ...state.paciente,
              ...payload
          }
        }
   
    case actionTypes.ERROR:
        return {
            ...state,
            error: payload
    }

    case actionTypes.SUCCESS:
      return {
         ...state, 
         ...payload 
        };

    default:
      return state
    }
};
