import { HttpAuth } from '../../config/Http'

export const actionTypes = {
    INDEX: 'DASHBOARD_INDEX',
}

export const change = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const index = () => dispatch => {
    return HttpAuth.get('/dashboard')
                .then(res => typeof res !== 'undefined' && dispatch(change(res.data)))
}
