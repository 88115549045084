import { HttpAuth } from "../../config/Http";
import { changeNotify } from "../../store/actions/notify.action";

export const actionTypes = {
  INDEX: "PRONTUARIO_INDEX",
  FNATENDIMENTO: "PRONTUARIO_FNATENDIMENTO",
  FNATENDIMENTOCHANGE: "PRONTUARIO_FNATENDIMENTOCHANGE",
  STORE_PREESCRICAO: "STORE_PREESCRICAO",
  CHANGE_PREESCRICAO: "CHANGE_PREESCRICAO",
  CHANGE_ATENDIMENTOS: "INDEX_ATENDIMENTOS",
  CLEANATENDIMENTO: "CLEANATENDIMENTO",
};

export const cleanAtendimento = (payload) => ({
  type: actionTypes.CLEANATENDIMENTO,
  payload,
});

export const change = (payload) => ({
  type: actionTypes.INDEX,
  payload,
});

export const changePreescricao = (payload) => ({
  type: actionTypes.CHANGE_PREESCRICAO,
  payload,
});

export const changeAtendimentos = (payload) => ({
  type: actionTypes.CHANGE_ATENDIMENTOS,
  payload,
});

export const index = (id) => (dispatch) => {
  return HttpAuth.get("/prontuario/atendimento/" + id).then(
    (res) => typeof res !== "undefined" && dispatch(change(res.data))
  );
};

export const indexAtendimentos = () => (dispatch) => {
  return HttpAuth.get("/atendimentos").then(
    (res) =>
      typeof res !== "undefined" && dispatch(changeAtendimentos(res.data))
  );
};

export const fnAtendimento = (id) => (dispatch) => {
  return HttpAuth.get("/prontuario/atendimento/finalizar/" + id).then(
    (res) => typeof res !== "undefined" && dispatch(change(res.data))
  );
};

//STORE PRONTUARIO
export const store = (data) => (dispatch) => {
  return HttpAuth.post("/prontuario/store", data).then((res) => {
    if (typeof res !== "undefined") {
      dispatch(
        changeNotify({
          open: true,
          msg: "Prontuário salvo com sucesso!",
          class: "success",
        })
      );
    }
  });
};

//STORE PREESCRICAO
export const store_preescricao = (data) => (dispatch) => {
  return HttpAuth.post("/preescricao/store", data).then((res) => {
    dispatch(changePreescricao(res.data));
  });
};
