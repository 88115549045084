import { actionTypes } from '../actions/dashboard.action';

const initialState = {
    dashboard: {
        total_agenda: 0,
        agendados: 0,
        nao_agendados: 0,
    }
}
 //eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {

    case actionTypes.INDEX:
        return { 
          ...state,
          dashboard: {
            ...state.dashboard,
            ...payload
          }
      }

    default:
      return state
    }
};
