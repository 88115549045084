import { HttpAuth } from "../../config/Http";
import { changeNotify } from "../../store/actions/notify.action";

export const actionTypes = {
  INDEX: "PACIENTES_INDEX",
  INDEXPACIENTE: "PACIENTE_INDEX",
  STORE: "PACIENTES_STORE",
  SHOW: "PACIENTES_SHOW",
  UPDATE: "PACIENTES_UPDATE",
  DESTROY: "PACIENTES_DESTROY",
  CHANGE: "PACIENTES_CHANGE",
  SUCCESS: "PACIENTES_SUCCESS",
  ERROR: "PACIENTES_ERROR",
  HISTORICO: "PACIENTES_HISTORICO"
};

export const change = (payload) => ({
  type: actionTypes.INDEX,
  payload,
});


export const change_paciente = (payload) => ({
  type: actionTypes.INDEXPACIENTE,
  payload,
});

export const change_historico = (payload) => ({
  type: actionTypes.HISTORICO,
  payload,
})

export const success = (payload) => ({
  type: actionTypes.SUCCESS,
  payload,
});

export const error = (payload) => ({
  type: actionTypes.ERROR,
  payload,
});



//INDEX
export const indexResponse = (payload, isLoadMore) => ({
    type: actionTypes.INDEX,
    payload,
    isLoadMore
})


export const index = (query, isLoadMore) => dispatch => {
  return HttpAuth.get('/pacientes?' + new URLSearchParams(query))
              .then((res) => typeof res !== 'undefined' && dispatch(indexResponse(res.data, isLoadMore)))
}


// STORE
//STORE PRONTUARIO
export const store = (data) => (dispatch) => {
  return HttpAuth.post("/pacientes/store", data).then((res) => {
    if (typeof res !== "undefined") {
          if(res.data.errors) {
              dispatch(error(res.data.errors))
          }

          if(res.data.success) {
              dispatch(
                changeNotify({
                  open: true,
                  msg: "Paciente salvo com sucesso!",
                  class: "success",
                })
              );
              dispatch(success({
                success: true
              }));
          }

    }
  });
};

//HISTORICO
export const historico = (id) => (dispatch) => {
  return HttpAuth.get("/paciente/historico/" + id).then(
    (res) => typeof res !== "undefined" && dispatch(change_historico(res.data))
  );
};
