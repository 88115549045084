import { actionTypes } from '../actions/prontuario.action';

const initialState = {
    paciente: {
       preescricoes: {}
    },
    atendimentos: []
    
}
 //eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {

    case actionTypes.INDEX:
        return { 
          ...state,
          paciente: {
            ...state.paciente,
            ...payload
        }
      }

      case actionTypes.CHANGE_ATENDIMENTOS:
        return { 
          ...state,
          atendimentos: [
            ...state.atendimentos,
            ...payload
          ]
      }

      case actionTypes.CHANGE_PREESCRICAO:
        return { 
          ...state,
          paciente: {
            ...state.paciente,
            ...payload
        }
      }

      case actionTypes.FNATENDIMENTO:
        return { 
          ...state,
          paciente: {
            ...state.paciente,
            ...payload
        }
      }

      case actionTypes.CLEANATENDIMENTO:
          return { 
          ...state,
          atendimentos: []
      }

    default:
      return state
    }
};
