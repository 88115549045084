import { actionTypes } from '../actions/alert.action'

const initialState = {
    open: false,
    class: 'success',
    time: 4000,
    msg: 'Dados atualizados'
}

// eslint-disable-next-line
export default (state = initialState, {type, payload}) => {
  switch (type) {

  case actionTypes.CHANGE:
    return { ...state, ...payload };

  default:
    return state
  }
};

