import { combineReducers } from 'redux'
import loadingReducer from './loading.reducer'
import notifyReducer from './notify.reducer'
import alertReducer from './alert.reducer'
import authReducer from './auth.reducer'
import prontuarioReducer from './prontuario.reducer'
import dashboardReducer from './dashboard.reducer'
import settingsReducer from './settings.reducer'
import pacientesReducer from './pacientes.reducer'
import calendarioReducer from './calendario.reducer'
import modalReducer from './modal.reducer';
import expedientesReducer from './expedientes.reducer'

const rootReducer = combineReducers({
    loadingReducer,
    notifyReducer,
    alertReducer,
    authReducer,
    prontuarioReducer,
    dashboardReducer,
    settingsReducer,
    pacientesReducer,
    calendarioReducer,
    modalReducer,
    expedientesReducer
})

export default rootReducer;