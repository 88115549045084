/* eslint-disable no-use-before-define */
import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { store_preescricao } from "../store/actions/prontuario.action";

import { getMemedURL } from "../utils/getMemedUrl";

const MemedContext = createContext({});

function MemedProvider({ children }) {
  const dispatch = useDispatch();

  const logOut = () => {
    try {
      // Parar os event listeners da Memed
      /* eslint-disable-next-line */
      MdHub.server.unbindEvents();

      // Remover os objetos principais da Memed
      // @ts-expect-error
      delete window.MdHub;
      // @ts-expect-error
      delete window.MdSinapsePrescricao;

      const scripts = Array.from(document.getElementsByTagName("script"));
      if (scripts && scripts.length > 0) {
        scripts.forEach((script) => {
          if (script.src.includes("memed.com.br"))
            script?.parentNode?.removeChild(script);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const definePacient = (data) => {
    return new Promise((resolve, reject) => {
      try {
        /* eslint-disable-next-line */
        MdHub.command
          .send("plataforma.prescricao", "setPaciente", {
            nome: data.paciente.name,
            endereco: data.paciente.logradouro,
            cidade: data.paciente.cidade,
            telefone: data.paciente.celular,
            cpf: data.paciente.cpf,
            idExterno: data.paciente.id_user,
          })
          .then(
            (response) => {
              resolve("");
            },
            (error) => {
              reject(error);
            }
          );
      } catch (err) {
        console.error(err);
      }
    });
  };

  const initEventsMemed = (token, atendimentoId) => {
    try {
      /* eslint-disable-next-line */
      MdSinapsePrescricao.event.add("core:moduleHide", (module) => {
        if (module.moduleName === "plataforma.prescricao") {
          console.info("====== Módulos da Memed encerrados ======");
        }
      });

      /* eslint-disable-next-line */
      MdSinapsePrescricao.event.add("core:moduleInit", (module) => {
        if (module.name === "plataforma.prescricao") {
          /* eslint-disable-next-line */
          MdHub.command.send("plataforma.prescricao", "setFeatureToggle", {
            // Desativa a opção de excluir um paciente
            deletePatient: false,
            // Desabilita a opção de remover/trocar o paciente
            removePatient: false,
            // Esconde o formulário de confirmação dos dados do paciente para receituário de controle especial
            // caso a cidade e o endereço tenham sido definidos com o comando `setPaciente`
            editPatient: false,
          });

          /* eslint-disable-next-line */
          MdHub.event.add("prescricaoImpressa", (prescriptionData) => {
            // No objeto "prescriptionData", é retornado as informações da prescrição gerada.
            // Implementar ações, callbacks, etc.
            console.info(
              "====== Prescrição gerada com sucesso ======",
              prescriptionData
            );
            dispatch(
              store_preescricao({
                token: token,
                id_atendimento: atendimentoId,
                preescricao_id: prescriptionData.prescricao.id,
              })
            );
          });
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const initMemed = (token, atendimentoId) => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("data-color", "#576cff");
    script.setAttribute("data-token", token);
    script.src = `https://${getMemedURL()}/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js`;
    script.onload = () => {
      initEventsMemed(token, atendimentoId);
    };
    document.body.appendChild(script);
  };

  const open = (module = "plataforma.prescricao") => {
    /* eslint-disable-next-line */
    MdHub.module.show(module);
  };

  return (
    <MemedContext.Provider value={{ logOut, open, initMemed, definePacient }}>
      {children}
    </MemedContext.Provider>
  );
}

const useMemed = () => {
  const context = useContext(MemedContext);

  if (!context) {
    throw new Error("useMemed must be used within an MemedProvider");
  }

  return context;
};

export { useMemed, MemedProvider };
