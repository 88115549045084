import { actionTypes } from '../actions/expedientes.action';

const initialState = {
    expediente: {},
    error: {},
    success: false,
}
 //eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ERROR:
        return {
            ...state,
            error: payload
    }

    case actionTypes.SUCCESS:
      return {
         ...state, 
         ...payload 
        };

    default:
      return state
    }
};
