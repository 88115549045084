import { HttpAuth } from '../../config/Http'
import { changeNotify } from '../actions/notify.action'
 
export const actionTypes = {
    INDEX: 'SETTING_INDEX',
    CHANGE: 'SETTING_CHANGE',
    SUCCESS: 'SETTING_SUCCESS',
    ERROR: 'SETTING_ERROR'
}

export const change = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const edit = (payload) => ({
    type: actionTypes.CHANGE,
  payload
})

export const index = () => dispatch => {
    return HttpAuth.get('/info')
            .then(res => typeof res !== 'undefined' && dispatch(change(res.data)))
}

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
  })

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const update = (data) => dispatch => {
    return HttpAuth.post('/setting', data) 
       .then(res => {
        if(res) {
              dispatch(changeNotify({
                open: true,
                msg: 'Dados alterados com sucesso!',
                class: 'success'
            }))
              dispatch(index());
              dispatch(success(true));
         }
       })
}

export const changePassword = (data) => dispatch => {
    return HttpAuth.post('/auth/change', data)
      .then()
} 